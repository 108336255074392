.searchbox-container {
  .searchbox-heading {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 300;
    color: purple;
  }
  .searchbox {
    font-family: lato;
    width: 30rem;
    font-size: 1.5rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border: 0.2rem solid lightgray;

    margin: 0rem 0 2rem 0;
    color: black;

    @media (max-width: 450px) {
      width: 34rem;
      font-size: 1.8rem;
    }
  }
}
