body {
  font-family: lato;
}

.header {
  margin: 2rem auto;
  border: 1px lightgray solid;
  border-radius: 3rem;
  width: 40rem;
  padding: 1rem;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 450px) {
    width: 34rem;
  }
}

.app-title {
  font-family: lato;
  font-weight: 300;
  font-size: 3rem;
  color: purple;
  margin: 1rem 0;

  @media (max-width: 450px) {
    font-size: 2.5rem;
  }
}

.app-title--sub {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 2rem;
  }
}

.app-description {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 300;
}

footer {
  background: #834d9b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #d04ed6,
    #834d9b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #d04ed6, #834d9b);

  .footer-title {
    font-size: 2rem;
    font-weight: 300;
    padding: 3rem;

    a {
      text-decoration: none;
      color: white;
    }
  }
}
