body {
  margin: 0;
  font-family: lato;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-text-content {
  margin: 0 auto;

  @media (max-width: 450px) {
    width: 16rem;
  }
}

.card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 35rem;
  height: 20rem;

  margin: 2rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

  background: #834d9b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #d04ed6,
    #834d9b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #d04ed6,
    #834d9b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: white;

  @media (max-width: 450px) {
    width: 34rem;
    height: 20rem;
  }

  .card-body_container {
    margin: 0 auto;
  }

  .card-image {
    width: 17rem;
    height: 20rem;
    // margin: 1rem 0 1rem 1rem;

    @media (max-width: 450px) {
      width: 50%;
      height: 20rem;
    }

    img {
      object-fit: cover;
      border-radius: 1rem 0 0 1rem;
      width: 100%;
      height: 100%;
    }
  }

  .card-title {
    margin-bottom: 1rem;
  }

  .card-name {
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .card-job {
    font-size: 1.3rem;
    font-weight: bold;
    color: gold;
    margin-bottom: 0.5rem;
  }

  .card-location {
    margin-bottom: 1rem;
  }

  .card-email {
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      color: white;
    }

    a:hover {
      color: gold;
    }
  }

  .card-website {
    color: white;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: gold;
    }
  }

  .card-social-media-container {
    a:hover {
      color: gold;
    }

    padding-bottom: 2rem;
    ul {
      list-style: none;
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      li {
        padding: 0 0.8rem;

        a {
          font-size: 2rem;
          color: white;
        }
      }
    }
  }
}
